'use client';
import { CourseNotFound } from '../../content/ErrorPage/CourseNotFound';
import { useEducatorParam } from '../../../hooks/useEducatorParam';
import { notFound } from 'next/navigation';
import { EducatorNotFound } from '../../content/ErrorPage/EducatorNotFound';
import Spinner from '../../../components/Spinner';
import styles from './styles.module.scss';

export default function Error404() {
    const { educator, status } = useEducatorParam();

    if (['fetching', 'initialised'].includes(status)) {
        return <Spinner innerClassName={styles.spinnerIn} outerClassName={styles.spinnerOut} />;
    }

    if (!educator || status === 'failed') {
        return <EducatorNotFound />;
    }

    return (
        <CourseNotFound
            educatorId={educator.educator._id}
            educatorName={educator.educator.name}
            educatorSlug={educator.educator.slug}
        />
    );
}
